import { render, staticRenderFns } from "./login-modal-content.vue?vue&type=template&id=c0d61882"
import script from "./login-modal-content.vue?vue&type=script&lang=js"
export * from "./login-modal-content.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginForm: require('/app/components/login/login-form.vue').default})
